"use strict";
exports.__esModule = true;
var React = require("react");
var project_1 = require("./project");
var image_1 = require("./image");
var text_1 = require("./text");
var title_1 = require("./title");
function ProjectView(props) {
    var filteredChildren = props.children.filter(function (project) {
        return (!props.filter) ||
            ((project.props.tags !== undefined) && (project.props.tags.indexOf(props.filter) >= 0));
    });
    return React.createElement("div", null, filteredChildren);
}
var filters = {
    'notebook': 'code notebooks',
    'plot': 'pen plots',
    'explorable': 'explorable explanations'
};
function Index(props) {
    return React.createElement("div", { className: "container" },
        props.filter ?
            React.createElement("div", { className: "blurb" },
                React.createElement("p", { className: "p2" },
                    "Only showing ",
                    filters[props.filter],
                    ". ",
                    React.createElement("a", { href: "/" }, "Show all."))) :
            React.createElement("div", { className: "blurb" },
                React.createElement("p", { className: "p1" },
                    "This is the home of the creative work of ",
                    React.createElement("a", { href: "https://paulbutler.org/" }, "Paul Butler"),
                    "."),
                React.createElement("p", { className: "p2" },
                    "I create ",
                    React.createElement("a", { href: "/plots" }, "pen plots"),
                    ", ",
                    React.createElement("a", { href: "/explorables" }, "explorable explanations"),
                    ", and ",
                    React.createElement("a", { href: "/notebooks" }, "code notebooks"),
                    " (among other things).")),
        React.createElement(ProjectView, { filter: props.filter },
            React.createElement(project_1.Project, null,
                React.createElement(image_1.Image, { src: "201903-pointillism/table.jpg" }),
                React.createElement(title_1.Title, { title: "Office Supply Pointillism", date: new Date('2019-03-06') }),
                React.createElement(image_1.Image, { src: "201903-pointillism/full.jpg" }),
                React.createElement(text_1.Text, null, "An attempt at creating a photorealistic reproduction of an image using 1,500 colored dot stickers."),
                React.createElement(image_1.Image, { src: "201903-pointillism/closeup.jpg" }),
                React.createElement(text_1.Text, null, "I used Floyd\u2013 Steinberg dithering to determine the dot placement and then placed the stickers by hand."),
                React.createElement(image_1.Image, { src: "201903-pointillism/pattern.png" })),
            React.createElement(project_1.Project, { tags: ['explorable'] },
                React.createElement(image_1.Image, { src: "201901-kelly/chart1.png" }),
                React.createElement(title_1.Title, { title: "The Kelly Criterion", date: new Date('2019-01-06'), url: "https://explore.paulbutler.org/bet/" }),
                React.createElement(image_1.Image, { src: "201901-kelly/bet.png" }),
                React.createElement(text_1.Text, null, "An explorable exploration of the Kelly Criterion for bet sizing."),
                React.createElement(image_1.Image, { src: "201901-kelly/section.png" })),
            React.createElement(project_1.Project, { tags: ['notebook'] },
                React.createElement(image_1.Image, { src: "201812-acosta/framerate.png" }),
                React.createElement(title_1.Title, { title: "Did The White House Alter a Video?", date: new Date('2018-12-06'), url: "https://nb.paulbutler.org/whitehouse-doctor-video/" }),
                React.createElement(image_1.Image, { src: "201812-acosta/codesection.png" }),
                React.createElement(text_1.Text, null, "A code notebook that takes a mathematical approach to forensic video analysis."),
                React.createElement(image_1.Image, { src: "201812-acosta/speedgraph.png" })),
            React.createElement(project_1.Project, { tags: ['explorable'] },
                React.createElement(title_1.Title, { title: "ranked.vote", date: new Date('2018-11-06'), url: "https://ranked.vote/" }),
                React.createElement(image_1.Image, { src: "201811-ranked.vote/sankey.png" }),
                React.createElement(text_1.Text, null, "A data portal that uses interactive visualizations to explain ranked choice election results."),
                React.createElement(image_1.Image, { src: "201811-ranked.vote/matrix.png" })),
            React.createElement(project_1.Project, { tags: ['explorable'] },
                React.createElement(title_1.Title, { title: "The Misunderstood Electoral College", date: new Date('2018-11-06'), url: "https://explore.paulbutler.org/electoral/" }),
                React.createElement(image_1.Image, { src: "201811-electoral/landing.png" }),
                React.createElement(text_1.Text, null, "An explorable explanation of some unintuitive implications of the electoral college."),
                React.createElement(image_1.Image, { src: "201811-electoral/pop_weight.png" })),
            React.createElement(project_1.Project, { tags: ['explorable'] },
                React.createElement(image_1.Image, { src: "201808-fractal/fractal.png" }),
                React.createElement(title_1.Title, { title: "Understanding the Mandelbrot Fractal", date: new Date('2018-08-13'), url: "https://explore.paulbutler.org/fractal/" }),
                React.createElement(image_1.Image, { src: "201808-fractal/complex.png" }),
                React.createElement(text_1.Text, null, "A tour of complex arithmatic, leading to the construction of the Mandelbrot fractal.")),
            React.createElement(project_1.Project, { tags: ['notebook', 'plot'] },
                React.createElement(image_1.Image, { src: "201803-tsp/progress.jpg" }),
                React.createElement(title_1.Title, { title: "Optimizing Plots with a TSP Solver", date: new Date('2018-03-25'), url: "https://nb.paulbutler.org/optimizing-plots-with-tsp-solver/" }),
                React.createElement(image_1.Image, { src: "201803-tsp/colors.jpg" }),
                React.createElement(image_1.Image, { src: "201803-tsp/visualizing.png" }),
                React.createElement(text_1.Text, null, "A runnable notebook that explains how to model plotter path optimization as a routing optimization problem."),
                React.createElement(image_1.Image, { src: "201803-tsp/final.jpg" })),
            React.createElement(project_1.Project, { tags: ['plot'] },
                React.createElement(image_1.Image, { src: "201801-montecarlo/finished.jpg" }),
                React.createElement(title_1.Title, { title: "Sketch Experiments", date: new Date('2018-01-14') }),
                React.createElement(image_1.Image, { src: "201801-montecarlo/process.jpg" }),
                React.createElement(text_1.Text, null, "A series of plots exploring ways to generate sketches from a photograph, including a Metropolis\u2013Hastings random walk."),
                React.createElement(image_1.Image, { src: "201801-montecarlo/lines.jpg" }),
                React.createElement(image_1.Image, { src: "201801-montecarlo/closeup.jpg" })),
            React.createElement(project_1.Project, { tags: ['notebook', 'plot'] },
                React.createElement(image_1.Image, { src: "201712-surface/spiral.jpg" }),
                React.createElement(title_1.Title, { title: "Surface Projection", date: new Date('2017-12-16'), url: "https://nb.paulbutler.org/surface-projection/" }),
                React.createElement(image_1.Image, { src: "201712-surface/notebook.png" }),
                React.createElement(image_1.Image, { src: "201712-surface/flowsnake.png" }),
                React.createElement(text_1.Text, null, "A series of plots using a technique I call surface projection, and a code notebook that explains it."),
                React.createElement(image_1.Image, { src: "201712-surface/hex.jpg" }),
                React.createElement(image_1.Image, { src: "201712-surface/hilbert.jpg" })),
            React.createElement(project_1.Project, { tags: ['notebook', 'plot'] },
                React.createElement(title_1.Title, { title: "Fractal Generation with L-Systems", date: new Date('2017-07-09'), url: "https://nb.paulbutler.org/l-systems/" }),
                React.createElement(image_1.Image, { src: "201707-lsystems/tree.jpg" }),
                React.createElement(text_1.Text, null, "A series of plots generated by Lindenmayer Systems, and a notebook explaining the technique."),
                React.createElement(image_1.Image, { src: "201707-lsystems/notebook.png" })),
            React.createElement(project_1.Project, { dark: true },
                React.createElement(image_1.Image, { src: "201612-treeverse/shot3.png" }),
                React.createElement(title_1.Title, { title: "Treeverse", date: new Date('2016-12-16'), url: "https://treeverse.app/" }),
                React.createElement(image_1.Image, { src: "201612-treeverse/shot2.png" }),
                React.createElement(text_1.Text, null, "A browser extension for visualizing the tree structure of Twitter conversations."),
                React.createElement(image_1.Image, { src: "201612-treeverse/shot1.png" })),
            React.createElement(project_1.Project, { dark: true },
                React.createElement(title_1.Title, { title: "Visualizing Flight Options", date: new Date('2013-01-16'), url: "https://paulbutler.org/2013/visualizing-flight-options/" }),
                React.createElement(image_1.Image, { src: "201301-flights/highlight.png" }),
                React.createElement(text_1.Text, null, "Exploration of an alternative UI for presenting international flight options."),
                React.createElement(image_1.Image, { src: "201301-flights/visualization.png" })),
            React.createElement(project_1.Project, { dark: true },
                React.createElement(image_1.Image, { src: "201212-nycbm/full.png" }),
                React.createElement(title_1.Title, { title: "New York City in Flags", date: new Date('2012-12-16'), url: "http://nycbm.bitaesthetics.com/" }),
                React.createElement(image_1.Image, { src: "201212-nycbm/detail.png" }),
                React.createElement(text_1.Text, null, "A map of New York City made from 60,000 flags, drawn proportionately from the birthplaces of the foreign-born inhabitants of each census tract."),
                React.createElement(image_1.Image, { src: "201212-nycbm/flags.png" }),
                React.createElement(text_1.Text, null, "Created for an art exhibition in Cleveland, Ohio."),
                React.createElement(image_1.Image, { src: "201212-nycbm/show.jpg" })),
            React.createElement(project_1.Project, { dark: true },
                React.createElement(title_1.Title, { title: "Toronto Transit Distance", date: new Date('2012-09-16'), url: "http://ttcmap.bitaesthetics.com/" }),
                React.createElement(image_1.Image, { src: "201209-transit/closeup.png" }),
                React.createElement(text_1.Text, null, "Interactive visualization of point-to-point transit times on Toronto\u2019s TTC transit network."),
                React.createElement(image_1.Image, { src: "201209-transit/fuller.png" }),
                React.createElement(text_1.Text, null, "Winner of the Kontagent Big Data Challenge ($10K prize.)"),
                React.createElement(image_1.Image, { src: "201209-transit/ui.png" })),
            React.createElement(project_1.Project, { dark: true },
                React.createElement(title_1.Title, { title: "Daily Deals", date: new Date('2011-02-16'), url: "http://gpvis.bitaesthetics.com/" }),
                React.createElement(image_1.Image, { src: "201102-groupon/closeup.png" }),
                React.createElement(text_1.Text, null, "Interactive visualization of Groupon deals collected from the web in 2011, shortly before their IPO."),
                React.createElement(image_1.Image, { src: "201102-groupon/stack.png" }),
                React.createElement(text_1.Text, null,
                    "Featured in the July-August 2011 ",
                    React.createElement("a", { href: "http://hbr.org/2011/07/vision-statement-deconstructing-the-groupon-phenomenon/" }, "Harvard Business Review")),
                React.createElement(image_1.Image, { src: "201102-groupon/full.png" })),
            React.createElement(project_1.Project, { dark: true },
                React.createElement(image_1.Image, { src: "201012-fbmap/california.png" }),
                React.createElement(title_1.Title, { title: "Visualizing Facebook Friends", date: new Date('2011-02-16'), url: "http://fbmap.bitaesthetics.com/" }),
                React.createElement(image_1.Image, { src: "201012-fbmap/europe.png" }),
                React.createElement(text_1.Text, null,
                    "Visualization of ten million Facebook friend pairs. Featured on the ",
                    React.createElement("a", { href: "https://www.facebook.com/notes/facebook-engineering/visualizing-friendships/469716398919/" }, "Facebook Engineering blog"),
                    " as well as ",
                    React.createElement("a", { href: "http://www.economist.com/blogs/dailychart/2010/12/data_visualisation_1" }, "The Economist"),
                    ",",
                    " ",
                    React.createElement("a", { href: "http://www.bbc.co.uk/news/science-environment-11989723" }, "BBC"),
                    ", and ",
                    React.createElement("a", { href: "http://newsfeed.time.com/2010/12/14/tracking-facebook-friendships-creates-a-stunning-global-map/" }, "Time"),
                    "."),
                React.createElement(image_1.Image, { src: "201012-fbmap/world.png" }))));
}
exports.Index = Index;

"use strict";
exports.__esModule = true;
var React = require("react");
var ReactDOM = require("react-dom");
var index_1 = require("./index");
document.addEventListener('DOMContentLoaded', function () {
    var element = document.getElementById('root');
    var filter = element.getAttribute('data-filter');
    console.log(filter);
    ReactDOM.hydrate(React.createElement(index_1.Index, { filter: filter }), element);
});

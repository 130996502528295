"use strict";
exports.__esModule = true;
var React = require("react");
var formatDate = function (date) { return new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' }).format(date); };
function Title(props) {
    return React.createElement("div", { className: "title panel" },
        React.createElement("p", { className: "name" }, props.url ? React.createElement("a", { href: props.url }, props.title) :
            props.title),
        React.createElement("p", { className: "date" }, formatDate(props.date)));
}
exports.Title = Title;

"use strict";
exports.__esModule = true;
var React = require("react");
var react_medium_image_zoom_1 = require("react-medium-image-zoom");
function Image(props) {
    return React.createElement("div", { className: "panel" },
        React.createElement(react_medium_image_zoom_1["default"], { image: {
                src: "/content-resized/280/" + props.src,
                srcset: "/content-resized/560/" + props.src + " 2x",
                className: "image",
            }, zoomImage: {
                src: "/content/" + props.src,
            }, defaultStyles: {
                overlay: {
                    background: '#111'
                }
            } }));
}
exports.Image = Image;
